@use 'sass:color';
@use 'sass:meta';
@use './libs/assets/sizes' as sizes;
@import '~mapbox-gl/dist/mapbox-gl.css';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;

  &.split-screen {
    overflow: hidden;
  }
}

* {
  font-family:
    'Rubik',
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen-Sans,
    Ubuntu,
    Cantarell,
    Helvetica Neue,
    Arial,
    sans-serif;
}

button {
  touch-action: manipulation;
}

.cdk-overlay-container {
  z-index: 999999;
}

.mat-mdc-dialog-content {
  max-height: 100%;
}

.mat-toolbar-multiple-rows {
  width: 100vw !important;
}

.mat-toolbar-row,
.mat-toolbar-single-row {
  padding: 0 20px 0 30px !important;
  background: #003057;
  width: 100vw !important;
  height: 42px;
}

.mat-toolbar-multiple-rows {
  height: 42px;
  min-height: 42px;
}

.text-center {
  text-align: center;
}

.mat-select {
  padding: 10px;
  border: 1px solid #000;
  width: auto !important;
}

.mat-mdc-dialog-container .mat-mdc-dialog-title {
  display: flex;
  padding-top: 10px;

  &::before {
    height: 0 !important;
  }
}

.ng-untouched {
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--invalid {
    .mat-mdc-notch-piece {
      border-color: var(--mdc-outlined-text-field-outline-color, var(--mat-app-outline));
    }
    .mdc-floating-label {
      color: var(--mdc-outlined-text-field-label-text-color, var(--mat-app-on-surface-variant));
    }
  }
}

@media screen and (min-width: 960px) {
  #front-chat-container {
    bottom: 70px !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .pac-container {
    zoom: 1.4;
  }
}

@media screen and (min-width: 1601px) {
  .pac-container {
    zoom: 1.6;
  }
}

.hidden-xs {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.hidden-sm {
  @media screen and (min-width: 768px) and (max-width: 959px) {
    display: none;
  }
}

.hidden {
  display: none !important;
}

.padding-20 {
  padding: 20px;
}

.form-row {
  margin-bottom: 20px;
  position: relative;

  .placeholder {
    color: #c0c0c0;
    margin: 0;
    position: absolute;
    padding: 0.714286em 0.857143em;
    z-index: 0;
    font-size: 14px;

    span {
      color: #7f7f7f;
    }
  }

  .hint {
    font-size: 10px;
    margin: 5px;
    text-transform: uppercase;
    position: absolute;
    top: 45px;
  }

  .error {
    color: #e0201b;
  }

  label {
    font-size: 12px;
    line-height: 14px;
    font-weight: 300;
    margin-bottom: 5px;
    a {
      font-weight: 500;
      color: #0261ad;
    }
  }

  input[type='checkbox'] {
    display: none;
  }

  &.form-row-checkbox {
    min-height: 25px;
  }

  .checkbox {
    background: #ffffff;
    border: 0.5px solid #707070;
    border-radius: 5px;
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    position: relative;

    .mat-icon {
      position: absolute;
      top: -1px;
      left: 1px;
      width: 20px;
    }

    & + label {
      margin-bottom: 0px;
    }

    &.invalid {
      border: 1.5px solid #e0201b;
      background: #f9dfdf;
    }
  }

  input[type='text'],
  input[type='number'],
  input[type='tel'],
  input[type='email'],
  textarea {
    appearance: none;
    transition:
      border-color 0.25s ease-in-out 0s,
      box-shadow 0.1s ease-in-out 0s,
      background-color 0.25s ease-in-out 0s,
      color 0.25s ease-in-out 0s;
    border: 1px solid rgb(216, 220, 222);
    width: 100%;
    box-sizing: border-box;
    vertical-align: middle;
    font-family: inherit;
    padding: 0.714286em 0.857143em;
    min-height: 36px;
    font-size: 16px;
    line-height: 1.28571;
    background: transparent;
    color: rgb(47, 57, 65);
    outline: 0 !important;
    z-index: 1;

    &:focus {
      border-color: #0261ad;
    }

    &.invalid {
      border-color: #c32e2e;
      color: #c32e2e;

      &::placeholder {
        color: #c32e2e;
      }
    }
  }

  textarea {
    resize: vertical;
    min-height: 80px;
    min-width: 300px;
  }

  /* Change the white to any color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  &.invalid {
    input[type='text'],
    input[type='number'],
    input[type='tel'],
    input[type='email'],
    textarea {
      &,
      &::placeholder {
        border-color: #c32e2e;
        color: #c32e2e;
      }
    }
  }
}

.mat-form-field-appearance-outline {
  .mat-form-field-outline-start,
  .mat-form-field-outline-end {
    border-radius: 0 !important;
  }

  .mat-form-field-infix {
    padding: 10px 0 10px 0;
  }

  .mat-form-field-label {
    top: 1.44375em;
  }
}

.mat-select-panel {
  border-radius: 0 !important;
  box-shadow: none !important;
  background: #f6f7f9;
  border: 0.5px solid #707070;
}

.primary-btn,
.primary-btn.ladda-button {
  cursor: pointer;
  padding: 8px 20px;
  border: 1px solid #0261ad;
  border-radius: 0;
  font-size: 16px;
  color: #0261ad;
  background: transparent;
  text-decoration: none;

  &.link-btn {
    border: none;
  }

  &.filled-btn {
    background-color: #0261ad;
    color: #ffffff;
  }

  &:focus-visible {
    outline: none;
  }

  @media screen and (min-width: 768px) {
    &:hover {
      background-color: aliceblue;
    }
  }

  &.warn-btn {
    color: white;
    border-color: #e0201b;
    background-color: #e0201b;
    padding: 8px 80px;
  }

  &[disabled] {
    border-color: #bfc0c0;
    color: #808080;
  }
}

.primary-page-btn,
.primary-page-btn.ladda-button,
.primary-page-btn.ladda-button[disabled] {
  cursor: pointer;
  padding: 9px 25px;
  color: #fff8f8;
  font-size: 18px;
  background-color: #e0201b;
  border: none;
  min-width: 160px;
  text-decoration: none;

  &.warn-btn {
    color: white;
    border-color: #e0201b;
    background-color: #e0201b;
  }

  &.success-btn {
    color: white;
    border-color: #20813a;
    background-color: #20813a;
  }

  &.gray-btn {
    color: white;
    border-color: #003057;
    background-color: #003057;
  }

  &[disabled] {
    opacity: 0.7;
  }

  .mat-icon {
    height: 18px;
    margin-left: -10px;
  }
}

.filled-btn {
  background: #0261ad;
  color: #ffffff;

  @media screen and (min-width: 768px) {
    &:hover {
      background: color.adjust(#0261ad, $lightness: -10%);
      color: #ffffff;
    }
  }
}

.btn-block {
  width: 100%;
}

$color-negative-rating: #0261ad !default;
$color-ok-rating: #0261ad !default;
$color-positive-rating: #0261ad !default;
$color-default-rating: #7b7b7b !default;
$default-star-height: 45px !default;
$default-star-width: 45px !default;
$default-star-character-empty: '\2605';

@import 'css-star-rating/scss/star-rating';

.rating.medium .star {
  cursor: pointer;
}

body {
  .content {
    max-width: 100%;
    margin: auto;
    font:
      400 16px 'Rubik',
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      Segoe UI,
      Roboto,
      Oxygen-Sans,
      Ubuntu,
      Cantarell,
      Helvetica Neue,
      Arial,
      sans-serif;
    box-sizing: border-box;
    background: #f6f7f9;
    min-height: 100vh;
    @media screen and (max-width: 767px) {
      padding: 0 0 100px 0;
    }
  }

  .primary-btn {
    font-size: 20px;
    &.warn-btn {
      &:hover {
        background-color: color.adjust(#e0201b, $lightness: -5%);
      }
    }
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }

  .divider {
    background-color: #0261ad;
    height: 1px;
    @media screen and (max-width: 767px) {
      margin-top: 10px;
    }
  }

  .header-staging {
    position: fixed;
    top: 8px;
    right: -24px;
    transform: rotate(45deg);
    width: 82px;
    height: 19px;
    color: #fff;
    background: #cc3340;
    line-height: 20px;
    font-size: 9px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    z-index: 1;
  }

  .page-header {
    background-color: #d1e1f2;
    padding: 15px 50px;
    h2 {
      font-size: 38px;
      margin: 0;
    }
    p {
      margin: 5px 0 10px 0;
    }

    @media (min-width: sizes.$mdMin) {
      .logo-arrow {
        background: #0261ad;
        height: 2px;
        width: 30px;
        position: relative;

        &:after {
          content: '';
          background: #0261ad;
          position: absolute;
          width: 2px;
          height: 15px;
          transform: rotate(50deg);
          right: 4px;
          top: -2px;
        }

        &::before {
          content: '';
          background: #0261ad;
          position: absolute;
          width: 2px;
          height: 15px;
          transform: rotate(-50deg);
          right: 4px;
          top: -11px;
        }
      }
    }

    img {
      max-height: 64px;

      &:last-child {
        max-width: 170px;
      }
    }

    @media screen and (max-width: 767px) {
      padding: 16px 20px;
      h2 {
        font-size: 26px;
        margin-top: 0;
      }
      p {
        font-size: 14px;
      }
    }

    @media screen and (max-height: 700px) and (min-width: 1199px) {
      padding: 10px 50px;
      h2 {
        margin-top: 0;
      }
      img,
      .logo-arrow {
        display: none;
      }
    }
  }

  .page-content {
    padding: 0 20px;
    min-height: calc(100vh - 184px);

    @media screen and (min-width: 960px) and (max-width: 1199px) {
      min-height: calc(100vh - 184px);
    }
    @media screen and (max-width: 767px) {
      min-height: calc(100vh - 141px);
      padding-top: 15px;
    }
    @media screen and (min-width: 1200px) and (max-height: 700px) {
      min-height: calc(100vh - 141px);
    }

    .step-content {
      @media screen and (min-width: 1200px) and (max-width: 1600px) {
        zoom: 1.4;
      }
      @media screen and (min-width: 1601px) and (max-width: 1919px) {
        zoom: 1.5;
      }
      @media screen and (min-width: 1920px) {
        zoom: 1.8;
      }
      @media screen and (min-width: 1200px) and (max-height: 800px) {
        zoom: 1.2;
      }
    }

    .back-button {
      color: #a8a8a9;
      background: none;
      border: none;
      font-size: 14px;
      font-weight: 300;
      text-transform: uppercase;
      padding: 8px 5px;
      margin-bottom: 10px;
      margin-top: -5px;
      cursor: pointer;

      .chevron:before {
        color: #c0c0c0;
        border-width: 1px 1px 0 0;
        height: 10px;
        left: 0.15em;
        position: relative;
        top: 0.15em;
        width: 10px;
      }
    }
  }

  .stripe-wrapper {
    p {
      text-align: center;
      margin-bottom: 20px !important;
      color: #0261ad;
    }

    .mat-spinner {
      margin: auto;
      height: 30px;
      width: 30px;

      circle {
        stroke: #0261ad;
      }
    }
  }

  #launcher,
  #webWidget {
    @media screen and (max-width: 959px) {
      bottom: 10px !important;
    }

    @media screen and (min-width: 1200px) and (max-height: 700px) {
      bottom: 10px !important;
    }
  }

  .progress-wrapper {
    width: 100%;
    @media screen and (max-width: 959px) {
      display: none !important;
    }

    @media screen and (min-width: 1200px) and (max-height: 700px) {
      display: none !important;
    }

    @media screen and (min-width: 960px) {
      background: #ffffff;
      width: 100vw;
      margin-left: -20px;
      margin-bottom: -31px;

      & > div {
        order: 2;

        .primary-page-btn {
          height: 100%;
          width: 240px;
        }
      }
    }
  }
  .form-row {
    input[type='text'],
    input[type='number'],
    input[type='tel'],
    input[type='email'],
    textarea {
      appearance: none;
      transition:
        border-color 0.25s ease-in-out 0s,
        box-shadow 0.1s ease-in-out 0s,
        background-color 0.25s ease-in-out 0s,
        color 0.25s ease-in-out 0s;
      border: 1px solid #0560ad;
      width: 100%;
      box-sizing: border-box;
      vertical-align: middle;
      font-family: inherit;
      padding: 0.714286em 0.857143em;
      min-height: 36px;
      font-size: 16px;
      line-height: 1.28571;
      background: transparent;
      color: rgb(47, 57, 65);
      outline: 0 !important;
      z-index: 1;

      &:focus {
        border-color: #0261ad;
      }

      &.invalid {
        border-color: #c32e2e;
        color: #c32e2e;

        &::placeholder {
          color: #c32e2e;
        }
      }
    }
  }

  .mat-accordion {
    margin-top: 10px;
    .mat-expansion-panel {
      box-shadow: none;
      background: transparent;
      color: #0261ad;
    }
  }
  .mat-expanded {
    .mat-expansion-panel-content {
      padding-top: 15px;
    }
  }
  .mat-expansion-panel-header {
    &,
    &:hover:not([aria-disabled='true']) {
      background: #e6eef5;
    }
  }
  .mat-stepper-vertical {
    background: #e6eef5;
    color: #0261ad;
  }
  .mat-vertical-stepper-header {
    padding: 20px 24px;
  }
  .mat-stepper-vertical-line::before {
    top: -11px;
    bottom: -11px;
    border-left-color: #0261ad;
  }
  .mat-step-header .mat-step-icon {
    color: #0261ad;
    border: 1px solid #0261ad;
    background: #d9e7f3;
    border-radius: 6px;
    opacity: 0.5;
    font-weight: bold;
  }
  .mat-step-header .mat-step-icon-selected,
  .mat-step-header .mat-step-icon-state-done,
  .mat-step-header .mat-step-icon-state-edit {
    opacity: 1;
  }
  .mat-vertical-content {
    padding: 0;
  }
  .mat-step-text-label {
    color: #0261ad;
    opacity: 0.5;
    font-weight: bold;
    .mat-icon {
      font-weight: bold;
    }
  }
  .mat-step-label-active {
    .mat-step-text-label {
      opacity: 1;
    }
  }
}

.mat-radio-button {
  &.mat-accent {
    .mat-radio-inner-circle {
      color: #0261ad;
      background-color: #0261ad;
    }

    &.mat-radio-checked {
      .mat-radio-outer-circle {
        border-color: #0261ad;
      }

      .mat-radio-persistent-ripple,
      .mat-ripple-element {
        background-color: #0261ad;
      }
    }

    .mat-radio-ripple .mat-ripple-element {
      background-color: #0261ad !important;
    }
  }
}

.mat-mdc-option .mdc-list-item__primary-text {
  min-width: 100%;
}
